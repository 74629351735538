import React from 'react'
import config from '../../../config'
import { Link, useNavigate } from 'react-router-dom';

export default function DashboardCourseBox({ data, deleteCourse, updateCourse }) {

    const navigate = useNavigate()

    function getHumanDate(d) {
        const date = new Date(d);
        const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
        return date.toLocaleDateString('ar', options);
    }

    return <>
        <div className={`dashboard-course-box ${data.pending ? 'pending' : ''}`}>
            <div className="image">
                <img src={`${data.cover}`} alt={data.name} className='w-100 h-100' />
            </div>
            <div className='title-desc'>
                <h6>
                    {data.name}
                </h6>
                <p className='p-0 m-0'>
                    {data.about}
                </p>
            </div>
            <div className="category-school-level">
                <p>
                    القسم : {data.center ? 'سنتر' : 'اونلاين'}
                </p>
                <p>
                    الصف :  {data.year.name}
                </p>
            </div>
            <div className="units-count">
                <i className="fa-solid fa-share-nodes"></i>
                <p>
                    {data.course_units.length} وحدات
                </p>
            </div>
            <div className='lessons'>
                <i className="fa-solid fa-tv"></i>
                <p>
                    {data.lessons_count} دروس
                </p>
            </div>
            <div className="course-price">
                <p>
                    {data.price}جـ
                </p>
                <i className="fa-solid fa-hand-holding-dollar"></i>
            </div>
            <div className="subscriptions" style={{cursor:'pointer'}} onClick={() => navigate(`/dashboard/course-subscriptions/${data.id}/${data.name}`)}>
                <i className="fa-solid fa-user-plus"></i>
                <p>
                    {data.student_in_course_count} مشترك
                </p>
            </div>
            <div className="subscriptions" style={{cursor:'pointer'}} onClick={() => navigate(`/dashboard/not-watch-lesson/${data.id}`)}>
            <i className="fa-regular fa-eye-slash"></i>
                <p>
                    لم يشاهد الكورس
                </p>
            </div>
            <div className="course-date">
                <p>
                    التاريخ
                </p>
                <p>
                    {getHumanDate(data.created)}
                </p>
            </div>
            <div className="add-units">
                <Link to={`/dashboard/units/${data.id}`} className='ms-2'> الوحدات </Link>
                <Link to={`/dashboard/exams/${data.id}/course`}> الامتحانات </Link>
                <Link to={`/dashboard/course-files/${data.id}/course`} className='me-2'> فايلات </Link>
            </div>
            <div className='setting-delete-btn'>
                <button onClick={() => updateCourse(data)}><i className="fa-solid fa-gear"></i></button>
                <button onClick={() => deleteCourse(data.id)}><i className="fa-solid fa-trash-can"></i></button>
            </div>
        </div>
    </>
}
