import { createBrowserRouter } from "react-router-dom";
// import GharabwyHome from "../Views/Theme/GharabwyHome";
import Layout from "../Views/Layout";
import NotFound from "../Views/NotFound";
import Login from "../Views/Auth/Login";
import AuthLayout from "../Views/Auth/AuthLayout";
import Register from "../Views/Auth/Register";
import Courses from "../Views/Courses";
import CoursePreview from "../Views/CoursePreview";
import ProfileLayout from "../Views/Profile/ProfileLayout";
import AuthGourd from "../Gourd/AuthGourd";
import AdminGourd from "../Gourd/AdminGourd";
import Profile from "../Views/Profile/ProfileSections/Profile";
import UserCourses from './../Views/Profile/ProfileSections/UserCourses';
import ExamsScore from "../Views/Profile/ProfileSections/ExamsScore";
import GuestGourd from "../Gourd/GuestGourd";
import UserCoursePreview from "../Views/UserCourses/UserCoursePreview";
import UserWatchCourse from "../Views/UserCourses/UserWatchCourse";
import CheckParams from "../Gourd/CheckParams";
import Quiz from "../Views/Quiz/Quiz";
import SecurityAndLogin from "../Views/Profile/ProfileSections/SecurityAndLogin";
import LevelIndicator from "../Views/Profile/ProfileSections/LevelIndicator";
import Notification from "../Views/Profile/ProfileSections/Notification";

// dashboard components
import DashboardLayout from "../Views/Dashboard/DashboardLayout";
import DashboardHome from "../Views/Dashboard/Home/DashboardHome";
import Students from "../Views/Dashboard/Students/Students";
import StudentDetails from "../Views/Dashboard/Students/StudentDetails";
import Categories from "../Views/Dashboard/Categories/Categories";
import DashboardCourses from "../Views/Dashboard/Courses/DashboardCourses";
import Units from "../Views/Dashboard/Units/Units";
import Lessons from "../Views/Dashboard/Lessons/Lessons";
import Videos from "../Views/Dashboard/Videos/Videos";
import Exams from "../Views/Dashboard/Exams/Exams";
import QuestionAnswers from "../Views/Dashboard/Exams/QuestionAnswers";
import SubscriptionsInvoices from "../Views/Dashboard/SubscriptionsInvoices/SubscriptionsInvoices";
import CreateCopon from "../Views/Dashboard/Copon/CreateCopon";
import PrevCopons from "../Views/Dashboard/Copon/PrevCopons";
import Users from "../Views/Dashboard/Users/Users";
import AddUser from "../Views/Dashboard/Users/AddUser";
import DashboardNotififcations from "../Views/Dashboard/Notifications/DashboardNotififcations";
import Attachments from "../Views/Dashboard/Attachments/Attachments";
import CourseSubscreptions from "../Views/Profile/ProfileSections/CourseSubscreptions";
import SubscriptionGaurd from "../Gourd/SubscriptionGaurd";
import HomeWorkScore from "../Views/Profile/ProfileSections/HomeWorkScore";
import StudentAnswers from "../Views/Dashboard/Exams/StudentAnswers";
import CourseSubscription from "../Views/Dashboard/CourseSubscription/CourseSubscription";
import CourseFiles from "../Views/Dashboard/Courses/CourseFiles";
import LessonByCode from "../Views/LessonByCode";
import WatchLessonByCode from "../Views/WatchLessonByCode";
import LessonViews from "../Views/Dashboard/LessonViews/LessonViews";
import Quizes from "../Views/Dashboard/Quizes/Quizes";
import QuizQuestionAnswers from "../Views/Dashboard/Quizes/QuizQuestionAnswers";
import QuizStudentAnswers from "../Views/Dashboard/Quizes/QuizStudentAnswers";
import HaveQuiz from "../Gourd/HaveQuiz";
import QuizBeforeLesson from "../Views/Quiz/QuizeBeforeLesson";
import Mohome from '../../src/Views/Theme/Abdullah/Mohome'
import CourseSubscriptions from "../Views/Dashboard/Courses/CourseSubscriptions";
import CategoriesSubscriptions from "../Views/Dashboard/Categories/CategoriesSubscriptions";
import StudentAllCourses from "../Views/StudentAllCourses";
import AllExams from "../Views/Dashboard/AllExams/AllExams";
import QuestionUpdate from "../Views/Dashboard/Exams/QuestionUpdate";
import ExamUpdate from "../Views/Dashboard/Exams/ExamUpdate";
import DashboardShowStudentAnswers from "../Views/Dashboard/Exams/DashboardShowStudentAnswers";
import PlateformInstractions from "../Views/PlateformInstractions";
import ShowExamAnswers from "../Views/Profile/ProfileSections/ShowExamAnswers";
import LastNews from "../Views/Dashboard/LastNews/LastNews";
import UpdateNews from "../Views/Dashboard/LastNews/UpdateNews";
import PrivacyPolicy from "../Views/PrivacyPolicy";
import RefundPolicy from "../Views/RefundPolicy";
import TermsConditions from "../Views/TermsConditions";
import UpdateStaff from "../Views/Dashboard/Users/UpdateStaff";
import CreateInvoice from "../Views/CreateInvoice";
import Invoice from "../Views/Invoice";
import CodeGenerate from "../Gourd/CodeGenerate";
import UserInvoices from "../Views/Profile/ProfileSections/UserInvoices";
import SessionProvider from "../Gourd/SessionProvider";
import BlockPage from "../Views/BlockPage";
import Block from "../Gourd/Block";
import NotWatchCourse from "../Views/Dashboard/Courses/NotWatchCourse";
import CenterCode from "../Gourd/CenterCode";

const router = createBrowserRouter([
    {
        path: "/", element: <Layout />, children: [
            {
                index: true, element: <CenterCode> <Mohome /></CenterCode>
            },
            {
                path: '/user-ban',
                element: <BlockPage />
            },
            {
                path: '/Auth',
                element: <GuestGourd>
                    <AuthLayout />
                </GuestGourd>,
                children: [
                    {
                        index: true, element: <Login />
                    },
                    {
                        path: 'register',
                        element: <Register />
                    }
                ]
            },
            {
                path: '/courses',
                element: <Courses />
            },
            {
                path: '/create-invoice/:id',
                element: <AuthGourd><CreateInvoice /></AuthGourd>
            },
            {
                path: '/invoice-code/:id',
                element: <AuthGourd> <CodeGenerate>  </CodeGenerate> </AuthGourd>
            },
            {
                path: '/invoice/:code/:id',
                element: <AuthGourd> <Invoice /> </AuthGourd>
            },
            {
                path: '/plateform-instraction',
                element: <PlateformInstractions />
            },
            {
                path: '/privacy-policy',
                element: <PrivacyPolicy />
            },
            {
                path: '/refund-policy',
                element: <RefundPolicy />
            },
            {
                path: '/terms-and-conditions',
                element: <TermsConditions />
            },
            {
                path: '/student-all-courses',
                element: <CenterCode><StudentAllCourses /></CenterCode>
            },
            {
                path: '/lesson-by-code',
                element: <LessonByCode />
            },
            {
                path: '/watch-lesson-by-code/:code',
                element: <WatchLessonByCode />
            },
            {
                path: '/course-preview/:course_id',
                element: <CenterCode><CoursePreview /></CenterCode>
            },
            {
                path: '/user-profile',
                element: <Block> <AuthGourd>
                    <ProfileLayout />
                </AuthGourd>
                </Block>,
                children: [
                    {
                        index: true,
                        element: <Profile />
                    },
                    {
                        path: 'my-courses',
                        element: <CenterCode> <UserCourses /> </CenterCode> 
                    },
                    {
                        path: 'my-exam-score',
                        element: <CenterCode>  <ExamsScore /> </CenterCode>
                    },
                    {
                        path: "security-and-login",
                        element: <CenterCode>  <SecurityAndLogin /> </CenterCode> 
                    },
                    {
                        path: 'level-indicator',
                        element: <CenterCode>  <LevelIndicator /> </CenterCode> 
                    },
                    {
                        path: "notification",
                        element: <CenterCode>  <Notification /> </CenterCode> 
                    },
                    {
                        path: 'course-subscreptions',
                        element: <CenterCode> <CourseSubscreptions /> </CenterCode> 
                    },
                    {
                        path: 'my-homework-score',
                        element: <CenterCode> <HomeWorkScore /></CenterCode> 
                    },
                    {
                        path: 'invoices',
                        element:<CenterCode>  <UserInvoices /> </CenterCode> 
                    },
                    {
                        path: 'show-exam-answers/:row_id/:exam_name',
                        element: <AuthGourd>
                            <CenterCode> <ShowExamAnswers /></CenterCode> 
                        </AuthGourd>
                    },
                ]
            },
            {
                path: '/my-course-preview/:course_id',
                element: <AuthGourd>
                    <SubscriptionGaurd>
                        <UserCoursePreview />
                    </SubscriptionGaurd>
                </AuthGourd>
            },
            {
                path: '/my-courses/:course_id/watch',
                element: <AuthGourd>
                    <SubscriptionGaurd>
                        <CheckParams>
                            <SessionProvider>
                                <HaveQuiz>
                                    <UserWatchCourse />
                                </HaveQuiz>
                            </SessionProvider>
                        </CheckParams>
                    </SubscriptionGaurd>
                </AuthGourd>
            },
            {
                path: '/quiz/:course_id/:exam_id',
                element: <AuthGourd>
                    <SubscriptionGaurd>
                        <Quiz />
                    </SubscriptionGaurd>
                </AuthGourd>
            },
            {
                path: '/quiz-before-lesson/:course_id/:exam_id',
                element: <AuthGourd>
                    <SubscriptionGaurd>
                        <QuizBeforeLesson />
                    </SubscriptionGaurd>
                </AuthGourd>
            },
            {
                path: "*",
                element: <NotFound />
            }
        ]
    },
    {
        path: '/dashboard',
        element: <AuthGourd>
            <AdminGourd>
                <DashboardLayout />
            </AdminGourd>
        </AuthGourd>,
        children: [
            {
                index: true,
                element: <DashboardHome />
            },
            {
                path: "students",
                element: <Students />
            },
            {
                path: "students/:student_id",
                element: <StudentDetails />
            },
            // {
            //     path: 'categories',
            //     element: <Categories />
            // },
            {
                path: 'courses',
                element: <DashboardCourses />
            },
            {
                path: 'units/:course_id',
                element: <Units />
            },
            {
                path: 'lessons/:unit_id',
                element: <Lessons />
            },
            {
                path: "videos",
                element: <Videos />
            },
            {
                path: 'exams/:id/:type',
                element: <Exams />
            },
            {
                path: 'questions-answers/:exam_id/:type',
                element: <QuestionAnswers />
            },
            {
                path: 'exam-results/:exam_id',
                element: <StudentAnswers />
            },
            {
                path: 'course-subscriptions',
                element: <CourseSubscription />
            },
            {
                path: 'course-files/:course_id/:type',
                element: <CourseFiles />
            },
            {
                path: 'all-exams',
                element: <AllExams />
            },
            {
                path: 'subscriptions/:search_by?/:search_value?/:page?',
                element: <SubscriptionsInvoices />
            },
            {
                path: 'create-copon',
                element: <CreateCopon />
            },
            {
                path: 'prev-copon',
                element: <PrevCopons />
            },
            {
                path: 'users',
                element: <Users />
            },
            {
                path: 'quizes/:lesson_id',
                element: <Quizes />
            },
            {
                path: 'quiz-questions-answers/:quiz_id',
                element: <QuizQuestionAnswers />
            },
            {
                path: 'quiz-results/:exam_id',
                element: <QuizStudentAnswers />
            },
            {
                path: 'lesson-views/:search_key?/:search_value?/:page?',
                element: <LessonViews />
            },
            {
                path: 'exam-question-update/:question_id/:exam_id/:type',
                element: <QuestionUpdate />
            },
            {
                path: 'exam-update/:exam_id/:course_id/:exam_name/:type',
                element: <ExamUpdate />
            },
            {
                path: 'add-user',
                element: <AddUser />
            },
            {
                path: 'show-student-answers/:row_id',
                element: <DashboardShowStudentAnswers />
            },
            {
                path: 'notifications',
                element: <DashboardNotififcations />
            },
            {
                path: 'place-subscriptions/:place_id/:place_name',
                element: <CategoriesSubscriptions />
            },
            {
                path: 'course-subscriptions/:course_id/:course_name',
                element: <CourseSubscriptions />
            },
            {
                path: 'attachments',
                element: <Attachments />
            },
            {
                path: 'last-news',
                element: <LastNews />
            },
            {
                path: 'update-news/:news_id',
                element: <UpdateNews />
            },
            {
                path: 'update-staff/:staff_id',
                element: <UpdateStaff />
            } ,
            {
                path: 'not-watch-lesson/:course_id/:page?' ,
                element: <NotWatchCourse />
            }
        ]
    }
]);

export default router;