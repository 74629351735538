import axios from 'axios'
import React, { useContext, useRef } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import config from '../config'
import { auth } from '../Context/AuthStore'
import Placeholder from '../Components/Used/Placeholder'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'

export default function CenterCode(props) {

    const {userAuth} = useContext(auth)
    const [haveCode, sethaveCode] = useState(false)
    const [isloading, setisloading] = useState(true)
    const navigate = useNavigate()

    const alert = () => {
        navigate('/user-profile')
        Swal.fire({
            text: ' يجب ادخال كود السنتر اولا ' ,
            icon: 'warning'
        })
    }

    const check =  () => {
        axios.get(`${config.basURL}student/student-profile` , {
            headers: {
                Authorization: config.ApiKey ,
                auth: `Bearer ${userAuth}`
            }
        }).then(res => {
            // console.log(res.data)
            setisloading(false)
            if(res.data.by_code) {
                res.data.center_code != null ? sethaveCode(true) : sethaveCode(false)
            }else {
                sethaveCode(true)
            }
        }).catch(err => {
            console.log(err)
        })
    }

    useEffect(() => {
        if(userAuth){
            check()
        }
    } , [userAuth])

  return <>
  {
     userAuth != null ?  (isloading ? <div className='loading-gurad w-100 h-100'><Placeholder /> </div> : 
    (haveCode ? props.children : alert())) : props.children
  }
  </>
}
