import React, { useContext, useEffect, useState } from 'react'
import MyCoursesBox from '../../../Components/Profile/MyCoursesBox'
import config from '../../../config';
import axios from 'axios';
import { auth } from '../../../Context/AuthStore'
import Placeholder from '../../../Components/Used/Placeholder'
export default function UserCourses() {

  const { userAuth } = useContext(auth)

  const [userCourses, setuserCourses] = useState(false)

  const getUserCourses = () => {
    axios.get(`${config.basURL}student/student-courses`, {
      headers: {
        'Authorization': config.ApiKey,
        'auth': `Bearer ${userAuth}`
      }
    }).then((res) => {
      // console.log(res.data)
      // let activeCourses = res.data.filter(course => course.active)
      setuserCourses(res.data.results)
    }).catch((err) => {
      console.log(err)
    })
  }

  useEffect(()=>{
    getUserCourses()
  }, [])

  return <>
    <div className='container my-courses-section' style={{minHeight:'100vh', paddingTop: '2rem'}}>
      <div className='row'>
        
        {
          userCourses? (
            userCourses.length > 0 ? 
            userCourses.map(course => <MyCoursesBox active={true} data={course} />)
            :
            <h5 className='text-center'>لا يوجد كورسات حتي الان</h5>
          )
          :
          <Placeholder />
        }
      </div>
    </div>
  </>
}
