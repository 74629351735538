import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import config from '../../../config'
import { auth } from '../../../Context/AuthStore'
import Placeholder from '../../../Components/Used/Placeholder'
import { useNavigate, useParams } from 'react-router-dom'

export default function CourseSubscriptions() {

    const navigation = useNavigate()

    const { course_id , course_name } = useParams()
    const { userAuth } = useContext(auth)
    const [studentsData, setstudentsData] = useState(false)
    const [studentCount, setstudentCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [nextPage, setNextPage] = useState(false)

    const getStudentInCourse = () => {
        setstudentsData(false)
        axios.get(`${config.basURL}dashboard/student-in-course/${course_id}?page=${currentPage}`, {
            headers: {
                'Authorization': config.ApiKey,
                'auth': `Bearer ${userAuth}`
            }
        }).then(res => {
            // console.log(res.data)
            
            setstudentsData(res.data.results)
            setstudentCount(res.data.count)
            res.data.next ? setNextPage(true) : setNextPage(false)
        }).catch(err => {
            console.log(err)
        })
    }

    useEffect(() => {
        getStudentInCourse()
    }, [userAuth])

    useEffect(() => {
        getStudentInCourse()
    } , [currentPage])

    return <>
        <div className="course-subscriptions container-fluid">
            <div className="section-header">
                <h3 className='m-0 p-0'>
                    الطلاب المشتركين في كورس { course_name }  : ( { studentCount } )
                </h3>
            </div>
            <table className='students-info student-table'>
                <thead>
                    <tr>
                        <th> التسلسل </th>
                        <th>
                            أسم الطالب
                        </th>
                        <th>
                            كود الطالب
                        </th>
                        <th>
                            رقم الطالب
                        </th>
                        <th>
                            رقم ولي الامر
                        </th>
                        <th>
                            المكان
                        </th>
                        <th>
                            المحافظه
                        </th>
                        {/* <th>
                            نوع الجهاز المسجل بيه
                        </th> */}
                        {/* <th>

                    </th> */}
                    </tr>
                </thead>
                <tbody>
                    {
                        studentsData ? studentsData.length > 0 ? studentsData.map((student, index) => <tr key={index} style={{ marginBottom: '15px' }}>
                            <td className='image' onClick={() => navigation(`/dashboard/students/${student.student.id}`)}>
                                {/* <img src={avatar} alt="" className='w-100 h-100' /> */}
                                {index + 1}
                            </td>
                            <td className='text-center' onClick={() => navigation(`/dashboard/students/${student.student.id}`)}>
                                {student.student.name}
                            </td>
                            <td className='text-center' onClick={() => navigation(`/dashboard/students/${student.student.id}`)}>
                                {student.student.code || 'لا يوجد'}
                            </td>
                            <td className='text-center' onClick={() => navigation(`/dashboard/students/${student.student.id}`)}>
                                {student.student.user.username}
                            </td>
                            <td className='text-center' onClick={() => navigation(`/dashboard/students/${student.student.id}`)}>d
                                {student.student.parent_phone}
                            </td>
                            <td className='text-center' onClick={() => navigation(`/dashboard/students/${student.student.id}`)}>
                                {student.student.place ? student.place.name :'  لا يوجد'}
                            </td>
                            <td className='text-center' onClick={() => navigation(`/dashboard/students/${student.student.id}`)}>
                                {student.student.governorate ? student.student.governorate : '  لا يوجد'}
                            </td>
                            {/* <td onClick={() => navigation(`/dashboard/students/${student.id}`)}>
                                Phone
                            </td> */}
                            {/* <td>
                            <button className='delete' onClick={() => deleteStudent(student.id)}>
                                <i className="fa-regular fa-trash-can"></i>
                            </button>
                        </td> */}
                        </tr>) : <tr> <td colSpan='8' className='w-100 text-center'> لا يوجد طلاب </td> </tr> : <tr><td className='w-100' colSpan={9}> <Placeholder /> </td></tr>
                    }
                </tbody>
            </table>
            <div className="pagination-btn">
                <button onClick={() => setCurrentPage((prev) => prev - 1)} className={`prev ${currentPage > 1 ? '' : 'disable'}`} disabled={currentPage > 1 ? false : true}> السابق </button>
                <button className={`next ${nextPage ? '' : 'disable'}`} disabled={nextPage ? false : true} onClick={() => setCurrentPage((prev) => prev + 1)}> التالي </button>
            </div>
        </div>
    </>
}
