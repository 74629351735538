import React from 'react'

export default function BlockPage() {
  return <>
  <div className="container d-flex justify-content-center align-items-center" style={{height: "100vh"}}>
        <div>
            <h2 className='text-center'> لقد تم حظر حسابك برجاء التواصل مع الدعم </h2>
        </div>
  </div>
  </>
}
