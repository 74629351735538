import axios from 'axios'
import { useEffect } from 'react'
import { useState } from 'react'
import config from '../config'
import { auth } from '../Context/AuthStore'
import Placeholder from '../Components/Used/Placeholder'
import { Navigate } from 'react-router-dom'
import React, { useContext } from 'react'

export default function Block(props) {

    const { userAuth } = useContext(auth)

    const [isBloking, setisBloking] = useState(false)

    const getUserProfileData = () => {
        axios.get(`${config.basURL}student/student-profile`, {
            headers: {
                'Authorization': config.ApiKey,
                'auth': `Bearer ${userAuth}`,
            }
        }).then((res) => {
            // console.log('bolok middle' , res.data)
            setisBloking(res.data)

        }).catch((err) => {
            console.log(err)
            if(err.response.data.block == true) {
                setisBloking({
                    block: true
                })
            }
        })
    }

    useEffect(() => {
        if (userAuth) {
            getUserProfileData()
        }
    }, [userAuth])

    return <>

        {
            isBloking ? (isBloking.block == true ? <Navigate to={'/user-ban'} /> : props.children) : <div className="subscription-gourd">
                <Placeholder />
            </div>
        }
    </>
}
