import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

export default function SearchBox({ feilds, search, open, switchOpen, loading }) {

    const [params, setparams] = useState({})
    const navigation = useNavigate()

    const addParam = (e) => {
        let data = { ...params }
        data[e.target.name] = e.target.value;
        if (e.target.value == '') {
            delete data[e.target.name]
        }
        setparams(data)
    }

    const close = (e) => {
        e.target.classList.contains('dashboard-search-box-layout') && switchOpen();
    }

    const searchHandller = (e) => {
        e.preventDefault()
        let all_queries = {...params}
        let link= ''
        if (Object.keys(all_queries).length > 0) {
            all_queries.page = 1
            let query_keys = Object.keys(all_queries)
            query_keys.forEach((key, index) => {
                if (index == 0) {
                    link += `?${key}=${all_queries[key]}`
                } else {
                    link += `&${key}=${all_queries[key]}`
                }
            });
        }
        navigation(`${search}${link}`)
        setparams({})
        switchOpen()
    }

    return <>
        <div className={`dashboard-search-box-layout ${open ? 'open' : ''}`} onClick={close}>
            <form className="search" onSubmit={searchHandller} >
                {
                    feilds.map(f => {
                        return <>
                            {
                                f.input && <div className="group">
                                    <label htmlFor={f.name}> {f.label} </label>
                                    <input type="text" name={f.name} id={f.name} onChange={addParam} />
                                </div>
                            }
                            {
                                f.select && <div className="group">
                                    <label htmlFor={f.name}> {f.label} </label>
                                    <select name={f.name} id={f.name} onChange={addParam} >
                                        {
                                            f.options && f.options.map(option => {
                                                return <>
                                                    <option value={option.id}> {option.name} </option>
                                                </>
                                            })
                                        }
                                    </select>
                                </div>
                            }
                        </>
                    })
                }
                <button>
                    {
                        loading ? <i className="fa-solid fa-circle-notch fa-spin"></i> : <>
                            <i className="ms-2 fa-solid fa-magnifying-glass"></i>
                            بحث
                        </>
                    }

                </button>
            </form>
        </div>
    </>
}
