import axios from 'axios'
import React from 'react'
import { useContext } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import config from "../../../config"
import { auth } from '../../../Context/AuthStore'
import Placeholder from '../../../Components/Used/Placeholder'
import Swal from 'sweetalert2'
import Validator from "../../../Validator"
import LessonViewsRow from '../../../Components/Dashboard/LessonViewsRow/LessonViewsRow'
import CSVMake from '../../../CSVMake'
import { useNavigate, useParams } from 'react-router-dom'

export default function LessonViews() {

    const params = useParams()
    const navigate = useNavigate()

    const { userAuth } = useContext(auth)
    const [lessonViews, setLessonViews] = useState(false)
    const [searchBy, setsearchBy] = useState('all')
    const [searchValue, setsearchValue] = useState({})
    const [nextPage, setNextPage] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [searchResults, setsearchResults] = useState(false)
    const [viewsCount, setviewsCount] = useState(0)

    const [haveNext, sethaveNext] = useState(false)
    const [havePrev, sethavePrev] = useState(false)

    const updateSearchBy = (e) => {
        let data = searchBy;

        if (e.target.value === 'all') {
            getAllViews()
        } else {
            data = e.target.value;
        }

        setsearchBy(data)
    }

    const updateSearchValue = (e) => {
        let data = { ...searchValue }
        data[e.target.name] = e.target.value;
        setsearchValue(data)
    }

    const gonextPage = () => {
        let currentPage = params.page ? Number(params.page) : 1 ,
            link = '/dashboard/lesson-views'
        if(params.search_by){
            link += `/${params.search_by}/${params.search_value}/${currentPage += 1}`
        }else {
            link += `/ / /${currentPage += 1}`
        }
        navigate(link)
    }
    const prevPage = () => {
        let currentPage = Number(params.page) ,
            link = '/dashboard/lesson-views'
        if(params.search_by){
            link += `/${params.search_by}/${params.search_value}/${currentPage -= 1}`
        }else {
            link += `/ / /${currentPage -= 1}`
        }
        navigate(link)
    }


    const getAllViews = () => {
        setLessonViews(false)

        let link = `${config.basURL}dashboard/lesson-views-list?page=${params.page || 1}`

        params.search_key && (link += `&${params.search_key}=${params.search_value}`)

        // console.log(link)
        // return

        axios.get(link, {
            headers: {
                'Authorization': config.ApiKey,
                'auth': `Bearer ${userAuth}`
            }
        }).then(res => {
            console.log(res.data)
            setviewsCount(res.data.count)
            setLessonViews(res.data.results)
            res.data.next ? setNextPage(true) : setNextPage(false)

            res.data.next ? sethaveNext(true) : sethaveNext(false) 
            res.data.previous ? sethavePrev(true) : sethavePrev(false) 
        }).catch(err => {
            console.log(err)
        })
    }

    const search = (e) => {
        e.preventDefault();
        navigate(`/dashboard/lesson-views/${searchBy}/${searchValue[searchBy]}`)
    }

    const exportCsv = () => {
        let csv = new CSVMake;
        csv.setTableHeader({
            student: ['name', 'اسم الطالب'],
            lesson: ['name', 'اسم الدرس'],
            watch_counte: 'عدد المشاهدات',
            watch_time: 'مده المشاهده'
        }).setData(lessonViews, {
            student: 'name',
            lesson: 'name'
        }).exportFile(` مشاهدات الدروس `)
    }

    useEffect(() => {
        if(userAuth){
            getAllViews()
        }
    }, [userAuth, params])

    return <>
        <div className="container-fluid lesson-views-page">
            <h3>
                المشاهدات : ( {viewsCount} )
            </h3>
            {
                lessonViews && <button className='export-file my-4' onClick={exportCsv}>
                    <i className="fa-regular fa-file-excel"></i>
                    انشاء ملف Excel
                </button>
            }
            <div className="leasson-views-search">
                <select name="search-by" id="" onChange={updateSearchBy}>
                    <option value="all"> البحث باستخدام... </option>
                    <option value="student__user__username">  رقم هاتف الطالب او الكود </option>
                    <option value="student__name"> اسم الطالب </option>
                    <option value="lesson__name"> اسم الدرس </option>
                    <option value="student__by_code"> مكان التسجيل  </option>
                </select>
                <form className='search-input' onSubmit={search}>
                    {
                        ['student__user__username' , 'student__name' , 'lesson__name', 'all' ].includes(searchBy) ? 
                        <input type="text" name={searchBy} onChange={updateSearchValue} />
                        :
                        <select className='w-100' name={searchBy} onChange={updateSearchValue}>
                            <option> اختار  </option>
                            <option value={true} > سنتر </option>
                            <option value={false} > اونلانن </option>
                        </select>
                    }
                    <button>
                        بحث
                    </button>
                </form>
            </div>
            <table className="content">
                <thead>
                    <tr>
                        <th> اسم الدرس </th>
                        <th> اسم الطالب </th>
                        <th> رقم الهاتف</th>
                        <th> مكان تسجيل الطالب </th>
                        <th> السنه الدراسية </th>
                        <th> عدد المشاهدات </th>
                        <th> مده المشاهده </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        lessonViews ? (lessonViews.length > 0 ? lessonViews.map(view => <LessonViewsRow key={view.id} data={view} refresh={getAllViews} />) : <tr>
                            <td colSpan={7} className='text-center'> لا توجد مشاهدات  </td>
                        </tr>) : <Placeholder />
                    }
                </tbody>
            </table>
            <div className="pagination-btn">
            <button onClick={prevPage} className={`prev ${havePrev ? '' : 'disable'}`} disabled={havePrev ? false : true}> السابق </button>
            <button className={`next ${haveNext ? '' : 'disable'}`} disabled={haveNext ? false : true} onClick={gonextPage}> التالي </button>
            </div>
        </div>
    </>
}
