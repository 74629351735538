import React, { useContext, useEffect, useRef, useState } from 'react'
import config from '../../../config';
import { auth } from '../../../Context/AuthStore';
import Swal from 'sweetalert2';
import axios from 'axios';

export default function Answers({ data, deleteAnswer, refresh, type, data_id, totalAnswers, allAnswers }) {

    var refdiv = useRef(null);
    var rte = useRef(null);
    const { userAuth } = useContext(auth)
    const [openUpdateAnswer, setopenUpdateAnswer] = useState(false)
    const [isLoading, setisLoading] = useState(false)
    const [answerUpdateData, setanswerUpdateData] = useState({
        correct: data.correct
    })

    const [isDublicate, setisDublicate] = useState(false)

    const closePopup = (e) => {
        if (e.target.classList.contains('add-new-popup')) {
            setopenUpdateAnswer(false)
        }
    }

    const updateNewAnswers = (e) => {
        let data = { ...answerUpdateData }
        data.correct ? data.correct = false : data.correct = true;
        setanswerUpdateData(data)
    }

    const togglePopup = () => {
        openUpdateAnswer ? setopenUpdateAnswer(false) : setopenUpdateAnswer(true)
    }

    const sendUpdateAnserData = (e) => {
        e.preventDefault();
        setisLoading(true)
        let sendData = { ...answerUpdateData }
        sendData.text = rte.current.getHTMLCode()

        // check if question have correct answer alredy

        let haveCorrectAnswer = allAnswers.filter(answer => answer.correct === true)
        if (sendData.correct == true) {
            if (haveCorrectAnswer.length > 0) {
                Swal.fire({
                    text: 'لا يمكن اضافه اكثر من اجابه صحيحه',
                    icon: 'warning'
                })
                setisLoading(false)
                togglePopup()
                return;
            }
        }

        axios.put(`${config.basURL}dashboard/answer-update/${data.id}`, sendData, {
            headers: {
                'Authorization': config.ApiKey,
                'auth': `Bearer ${userAuth}`
            }
        }).then(res => {
            setisLoading(false)
            togglePopup()
            refresh()
            Swal.fire({
                text: 'تم التعديل بنجاح',
                icon: 'success'
            })
        }).catch(err => {
            setisLoading(false)
            togglePopup()
            Swal.fire({
                text: 'حدث خطاء برجاء المحاوله مره اخري',
                icon: 'error'
            })
            console.log(err)
        })
    }

    const dublicateAnswer = () => {
        setisDublicate(true)
        let answerNo = `answer-${totalAnswers + 1}`
        let ans = {
            answers_set: {}
        }
        ans.answers_set[answerNo] = {
            ...data,
            correct: false
        }
        axios.post(`${config.basURL}dashboard/${type}-answer/${data_id}`, ans, {
            headers: {
                'Authorization': config.ApiKey,
                'auth': `Bearer ${userAuth}`,
            }
        }).then(res => {
            setisDublicate(false)
            refresh()
            Swal.fire({
                text: 'تم الاضافه بنجاح',
                icon: 'success'
            })
            console.log(res.data)
        }).catch(err => {
            setisDublicate(false)
            console.log(err)
        })
    }


    useEffect(() => {
        // console.log(data)
        rte.current = new window.RichTextEditor(refdiv.current);
        rte.current.setHTMLCode(data.text);
    }, [])

    return <>
        <div className='answer'>
            <div className="check-correct">
                {
                    data.correct ? <i className="fa-solid fa-square-check" style={{ color: '#2ecc71' }}></i> : <i className="fa-solid fa-square-xmark" style={{ color: '#e74c3c' }}></i>
                }
            </div>
            <p dangerouslySetInnerHTML={{ '__html': data.text }} />
            <div className="left">
                <button className='setting' onClick={dublicateAnswer} title='نسخ الاجابه'>
                    {
                        isDublicate ?
                            <i className="fa-solid fa-circle-notch fa-spin"></i>
                            :
                            <i className="fa-regular fa-copy"></i>
                    }
                </button>
                <button className='setting' onClick={togglePopup} style={{ color: '#000' }} >
                    <i className="fa-solid fa-gear"></i>
                </button>
                <button className='delete' onClick={() => deleteAnswer(data.id)}>
                    <i className="fa-regular fa-trash-can"></i>
                </button>
            </div>
        </div>

        {/* add new answer popup */}
        <div className={`add-new-popup ${openUpdateAnswer ? 'open' : ''}`} onClick={closePopup}>
            <form className='new-box' onSubmit={sendUpdateAnserData}>
                <div ref={refdiv} className='text'></div>
                <div className='check'>
                    <input type="checkbox" name="correct" id="correct-answer" value={true} onChange={updateNewAnswers} checked={answerUpdateData.correct ? true : false} />
                    <label htmlFor="correct-answer"> الاجابه الصحيحه </label>
                </div>
                <button>  {isLoading ? <i className="fa-solid fa-circle-notch fa-spin"></i> : 'تعديل'}   </button>
            </form>
        </div>
    </>
}