import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { auth } from '../Context/AuthStore'
import Swal from 'sweetalert2'

export default function SubmenuHeader() {
    let location = useLocation()
    const { userAuth, logout } = useContext(auth)
    const [show, setshow] = useState(false)

    const logout_user = () => {
        Swal.fire({
            title: " هل انت متاكد من تسجيل الخروج ",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "تسجيل الخروج",
            cancelButtonText: 'الغاء',
        }).then((result) => {
            if (result.isConfirmed) {
                logout()
                Swal.fire({
                    title: " تم تسجيل الخروج بنجاح ",
                    icon: "success"
                });
            }
        });
    }

    const showmenu = () => {
        show ? setshow(false) : setshow(true)
    }

    useEffect(() => {
        if (show) {
            setshow(false)
        }
    }, [location.pathname])

    // const [open, setopen] = useState(second)
    return (
        <div className={`submenu-header ${userAuth && 'auth'}`}>
            <div className='container'>
                <div className='open-mobile text-center hidden-lg' onClick={showmenu}>
                    <i class="fa-solid fa-chevron-down" style={{ fontSize: '1.5rem' }}></i>
                </div>
                <ul className={`d-flex align-items-center justify-content-between w-100 ${show && 'show'}`}>
                    <li className='hidden-lg'>
                        <Link to={'/'} >
                            <i className="fa-solid fa-house"></i>
                            الصفحة الرئيسية
                        </Link>
                    </li>
                    {
                        userAuth &&
                        <>
                            <li className={`${location.pathname === '/user-profile' ? 'active' : ''}`}>
                                <Link to={'/user-profile'} >
                                    <i className="fa-regular fa-user"></i>
                                    ملف المستخدم
                                </Link>
                            </li>
                            <li className={``}>
                                <Link to={`/student-all-courses`} >
                                    <i className="fa-solid fa-book-bookmark"></i>
                                    جميع الكورسات
                                </Link>
                            </li>
                            <li className={`${location.pathname === '/user-profile/my-courses' ? 'active' : ''}`}>
                                <Link to={'/user-profile/my-courses'}>
                                    <i className="fa-solid fa-table-cells-large"></i>
                                    كورساتي
                                </Link>
                            </li>
                            <li>
                                <Link to={'/user-profile/invoices'}>
                                    <i className="fa-regular fa-file-excel"></i>
                                    الفواتير
                                </Link>
                            </li>
                            <li className={`${location.pathname === '/user-profile/course-subscreptions' ? 'active' : ''}`}>
                                <Link to={'/user-profile/course-subscreptions'}>
                                    <i className="fa-solid fa-circle-plus"></i>
                                    الاشتراكات
                                </Link>
                            </li>
                            <li className={`${location.pathname === '/user-profile/my-exam-score' ? 'active' : ''}`}>
                                <Link to={'/user-profile/my-exam-score'}>
                                    <i className="fa-regular fa-circle-check"></i>
                                    نتائج الامتحانات
                                </Link>
                            </li>
                            <li className={`${location.pathname === '/user-profile/my-homework-score' ? 'active' : ''}`}>
                                <Link to={'/user-profile/my-homework-score'}>
                                    <i className="fa-regular fa-clipboard"></i>
                                    نتائج الواجب
                                </Link>
                            </li>
                            <li>
                                <Link to={'/user-profile/notification'}>
                                    <i className="fa-regular fa-bell"></i>
                                    الاشعارات
                                </Link>
                            </li>
                        </>
                    }

                    <li className='hidden-lg'>
                        <Link to={'/lesson-by-code'} >
                        <i className="fa-solid fa-ticket-simple"></i>
                            الاكواد
                        </Link>
                    </li>
                    <li className='hidden-lg'>
                        <Link to={'/plateform-instraction'} >
                        <i className="fa-solid fa-person-chalkboard"></i>
                            تعليمات المنصة
                        </Link>
                    </li>
                    {
                        userAuth ?
                            <>
                                <li onClick={logout_user} className='logout-btn'>
                                    <i className="fa-solid fa-arrow-right-from-bracket"></i>
                                    تسجيل الخروج
                                </li>
                            </>
                            :
                            <>
                                <li>
                                    <Link to={'/auth/register'} >
                                        {/* <img src={newUserLogo} /> */}
                                        <i className="fa-solid fa-user-plus"></i>
                                        حساب جديد
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'/auth'} >
                                        {/* <img src={loginLogo} /> */}
                                        <i className="fa-solid fa-arrow-right-to-bracket fa-flip-horizontal"></i>
                                        تسجيل الدخول
                                    </Link>
                                </li>
                            </>
                    }

                </ul>
            </div>
        </div>
    )
}
