import React, { useEffect, useRef } from 'react'
import Swal from 'sweetalert2';

export default function Video({ videoLink, firstTime, sendWatchTry, setIntervalID, setWatchTime, intervalID, sendWatchTime, avilabile, watchTime }) {

    const media = useRef()

    // // video play handler
     // record user watch time
     useEffect(() => {
        let hasLogged = false;
        let cumulativeTime = 0;
        let lastTime = 0;

        let handller = () => {
            // Calculate time watched since the last update
            const deltaTime = media.current.currentTime - lastTime;
            lastTime = media.current.currentTime;

            // Only add positive deltas to the cumulative time (skip backwards seeks)
            if (deltaTime > 0) {
                cumulativeTime += deltaTime;
            }

            const duration = media.current.duration;
            const targetTime = duration * 0.3;

            setWatchTime(lastTime)

            if (!hasLogged && cumulativeTime >= targetTime) {
                sendWatchTry()
                console.log("User has cumulatively watched 30% of the video.");
                hasLogged = true; // Ensure the log happens only once
            }
        }

        const handleSeek = function () {
            lastTime = media.current.currentTime;
        }

        // Update lastTime on seek (to handle when user manually changes the play position)
        
        media.current.addEventListener('timeupdate', handller)
        media.current.addEventListener('seeking',handleSeek );
        return () => {
            media.current.removeEventListener('timeupdate', handller)
            media.current.removeEventListener('seeking', handleSeek)
        }

    }, [firstTime, watchTime])

    // // pause record user watch time
    useEffect(() => {
        const onVideoPause = () => {
            clearInterval(intervalID)
            sendWatchTime()
        }
        media.current.addEventListener('pause', onVideoPause)
        return () => {
            try {
                media.current.removeEventListener('pause', onVideoPause)
            } catch (err) {
                console.log(err)
            }
        }
    }, [intervalID, watchTime])

    // // video end handler
    useEffect(() => {
        const onEndedVideo = () => {
            sendWatchTime();
            clearTimeout(intervalID);
        }
        media.current.addEventListener('ended', onEndedVideo)
        return () => {
            try {
                media.current && media.current.removeEventListener('ended', onEndedVideo)
            } catch (err) {
                console.log(err)
            }
        }
    }, [watchTime])

    useEffect(() => {
        if (!avilabile) {
            clearInterval(intervalID)
            Swal.fire({
                text: 'انتهت مشاهداتك لهذا الدرس',
                icon: 'info'
            })
        }
    }, [avilabile])

    return <>
        {
            avilabile ? <video ref={media} className='w-100 rounded' controls>
                <source src={videoLink} type='video/mp4' />
            </video> : <div className="alert alert-info text-center">انتهت مشاهداتك للدرس</div>
        }

    </>
}
