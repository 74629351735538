import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { auth } from '../../../Context/AuthStore'
import config from '../../../config';
import { useNavigate, useParams } from 'react-router-dom';
import Placeholder from '../../../Components/Used/Placeholder'
import CSVMake from '../../../CSVMake'

export default function NotWatchCourse() {

    const { userAuth } = useContext(auth);
    const { course_id, page } = useParams()
    const [data, setdata] = useState(false)
    const navigation = useNavigate()

    const [haveNext, sethaveNext] = useState(false)
    const [havePrev, sethavePrev] = useState(false)

    const getData = () => {

        let link = `${config.basURL}center/views-student/${course_id}`;

        if (page) {
            link += `?page=${page}`
        }

        axios.get(link, {
            headers: {
                'Authorization': config.ApiKey,
                'auth': `Bearer ${userAuth}`
            }
        }).then(res => {
            console.log(res.data)
            setdata(res.data)
        }).catch(err => {
            console.log(err)
        })
    }

    const nextPage = () => {
        let currentPage = page ? Number(page) : 1,
            link = `/dashboard/not-watch-lesson/${course_id}`;

        link += `/${currentPage += 1}`
        navigation(link)
    }
    const prevPage = () => {
        let currentPage = page ? Number(page) : 1,
            link = `/dashboard/not-watch-lesson/${course_id}`;

        link += `/${currentPage -= 1}`
        navigation(link)
    }

    const exportCsv = () => {
        let csv = new CSVMake;
        csv.setTableHeader({
            course_name: 'اسم الكورس',
            lesson_name: 'اسم الدرس',
            student_name: ' اسم الطالب ' ,
            student__user__username: ' رقم هاتف الطالب ',
            student__parent_phone: ' رقم هاتف ولي الامر ',
            student_code: ' كود الطالب '
        }).setData(data).exportFile(` بيانات الطلاب لم يشاهدو كورس ${data && data[0].course_name} `)
    }

    useEffect(() => {
        getData()
    }, [])


    return <>
        <div className="course-subscriptions container-fluid">
            <div className="section-header d-flex align-items-center justify-content-between">
                <h3 className='m-0 p-0'>
                    الطلاب المشتركين في كورس {data && data[0].course_name} ولم يشاهدوا الدروس
                </h3>
                {
                    data && <button className='export-file' onClick={exportCsv}>
                        <i className="fa-regular fa-file-excel"></i>
                        انشاء ملف Excel
                    </button>
                }
            </div>
            <table className='students-info student-table'>
                <thead>
                    <tr>
                        <th> التسلسل </th>
                        <th>
                            أسم الطالب
                        </th>
                        <th>
                            كود الطالب
                        </th>
                        <th>
                            رقم الطالب
                        </th>
                        <th>
                            رقم ولي الامر
                        </th>
                        <th>
                            اسم الكورس
                        </th>
                        <th>
                            اسم الدرس
                        </th>

                        {/* <th>
                            المحافظه
                        </th> */}
                    </tr>
                </thead>
                <tbody>
                    {
                        data ? data.length > 0 ? data.map((student, index) => <tr key={index} style={{ marginBottom: '15px' }}>
                            <td className='image'>
                                {index + 1}
                            </td>
                            <td className='text-center' >
                                {student.student_name}
                            </td>
                            <td className='text-center' >
                                {student.student_code || 'لا يوجد'}
                            </td>
                            <td className='text-center'>
                                {student.student__user__username}
                            </td>
                            <td className='text-center'>
                                {student.student__parent_phone}
                            </td>
                            <td className='text-center'>
                                {student.course_name}
                            </td>
                            <td className='text-center' >
                                {student.lesson_name}
                            </td>

                            {/* <td className='text-center' onClick={() => navigation(`/dashboard/students/${student.student.id}`)}>
                                {student.student.governorate ? student.student.governorate : '  لا يوجد'}
                            </td> */}
                        </tr>) : <tr> <td colSpan='8' className='w-100 text-center'> لا يوجد طلاب </td> </tr> : <tr><td className='w-100' colSpan={9}> <Placeholder /> </td></tr>
                    }
                </tbody>
            </table>
            {/* <div className="pagination-btn">
                <button onClick={prevPage} className={`prev ${havePrev ? '' : 'disable'}`} disabled={havePrev ? false : true}> السابق </button>
                <button className={`next ${haveNext ? '' : 'disable'}`} disabled={haveNext ? false : true} onClick={nextPage}> التالي </button>
            </div> */}
        </div>
    </>
}
