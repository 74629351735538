import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';

export default function Videocipher({ avilabile, videoData, firstTime, watchTime, sendWatchTime, intervalID, setIntervalID, setWatchTime, sendWatchTry }) {

    const location = useLocation()

    const player = useRef();
    const iframe = useRef();
    // record user watch time
    const [cumulativeTime, setCumulativeTime] = useState(0);
    const [lastTime, setLastTime] = useState(0);
    const [hasLogged, setHasLogged] = useState(false);
    const threshold = 0.3; // 30%

    useEffect(() => {
        player.current = window.VdoPlayer.getInstance(iframe.current);
    }, [])
    

    useEffect(() => {
        const video = player.current.video;

        const handleTimeUpdate = () => {
            const currentTime = video.currentTime;
            const deltaTime = currentTime - lastTime;

            if (deltaTime > 0) {
                setCumulativeTime((prevTime) => prevTime + deltaTime);
            }

            setLastTime(currentTime);

            const duration = video.duration;
            const targetTime = duration * threshold;

            // console.log(cumulativeTime)
            setWatchTime(cumulativeTime)

            if (!hasLogged && cumulativeTime >= targetTime) {
                sendWatchTry()
                console.log('User has cumulatively watched 30% of the video.');
                setHasLogged(true); // Ensure the log happens only once
            }
        };

        const handleSeeking = () => {
            setLastTime(video.currentTime);
        };

        if (video) {
            video.addEventListener('timeupdate', handleTimeUpdate);
            video.addEventListener('seeking', handleSeeking);
        }

        // Cleanup event listeners on component unmount
        return () => {
            if (video) {
                video.removeEventListener('timeupdate', handleTimeUpdate);
                video.removeEventListener('seeking', handleSeeking);
            }
        };
    }, [lastTime, cumulativeTime, hasLogged]);

    // pause record user watch time
  

    // handle when video is end
    useEffect(() => {
        const handleEndedVideo = () => {
            sendWatchTime();
            clearTimeout(intervalID);
        }
        player.current.video.addEventListener('ended', handleEndedVideo)
        return () => {
            player.current.video.removeEventListener('ended', handleEndedVideo)
        }
    }, [watchTime])

    // stop counter if video not avilabile
    useEffect(() => {
        if (!avilabile) {
            clearInterval(intervalID)
            Swal.fire({
                text: 'انتهت مشاهداتك لهذا الدرس',
                icon: 'info'
            })
        }
    }, [avilabile])

    return <>
        {
            avilabile ? <iframe
                ref={iframe}
                src={`https://player.vdocipher.com/v2/?otp=${videoData && videoData.otp}&playbackInfo=${videoData && videoData.playbackInfo}`}
                style={{ 'border': 0 }}
                allow="encrypted-media"
                allowFullScreen
                id='frame'
            ></iframe> :
                <div className="alert alert-info text-center">انتهت مشاهداتك للدرس</div>
        }
    </>
}
