import React, { useContext, useEffect, useRef, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import '../App.css';
import Swal from 'sweetalert2';
import axios from 'axios';
import config from '../config';
import { auth } from '../Context/AuthStore';
import SubmenuHeader from '../Components/SubmenuHeader';

export default function Layout() {
  const navigate = useNavigate()
  const location = useLocation()
  const [watchingVideo, setwatchingVideo] = useState(false)
  const {userAuth} = useContext(auth)

  const sendWatchTime = () => {
    console.log(localStorage.getItem('watch_time'))
    axios.put(`${config.basURL}subscription/lesson-views`, {
      watch_time: localStorage.getItem('watch_time') ,
      lesson: localStorage.getItem('lesson_id')
    },
      {
        headers: {
          'Authorization': config.ApiKey,
          'auth': `Bearer ${userAuth}`
        }
      }).then((res) => { }).catch((err) => {
        console.log(err)
      })
  }

  const goToSupport = () => {
    window.location.replace('https://api.whatsapp.com/send?phone=201113533306')
  }

  useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }, [location.pathname])

  const showSwal = (message, icon, redirect = '') => {
    Swal.fire({
      text: message,
      didClose: () => redirect && navigate(redirect),
      icon: icon
    })
  }

  
  useEffect(() => {

    if(location.pathname.includes('/watch')){
      setwatchingVideo(true)
    }else {
      if(watchingVideo){
        setwatchingVideo(false)
        console.log(localStorage.getItem('watch_time'))
        sendWatchTime()
      }
    }

  } , [location.pathname])


  return <>
    <Header></Header>
    <SubmenuHeader />
    <Outlet context={{ showSwal }}></Outlet>
    <Footer></Footer>
    <div className='contact-support' onClick={goToSupport}>
      <div className="icon">
        <i className="fa-brands fa-whatsapp"></i>
      </div>
      <p> اتواصل مع الدعم </p>
    </div>
  </>
}
