import React, { useContext, useEffect, useState } from 'react'
import defaultUserImage from '../assets/default-user-image.png';
import mobileMenuIcon from '../assets/menu.png';
import homeLogo from "../assets/home.svg"
import newUserLogo from "../assets/new-user.svg"
import loginLogo from "../assets/login.svg"
import userAuthImage from '../assets/auth/user-auth.png'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { auth } from './../Context/AuthStore';
import { darkMode } from '../Context/DarkModeContext';
import logo from "../assets/gharabawy/logo.svg";
import logo1 from '../assets/gharabawy/logo.svg'
export default function Header() {

    const navigate = useNavigate()

    let location = useLocation()

    let { userAuth, logout } = useContext(auth)
    let { ToggleDarkTheme, darkTheme } = useContext(darkMode)

    const [openMenu, setOpenMenu] = useState(false)
    const [removeShadow, setRemoveShadow] = useState(false)
    const [collapse, setCollapse] = useState(false)
    const [lightMode, setLightMode] = useState(true)

    const showMenu = () => {
        openMenu ? setOpenMenu(false) : setOpenMenu(true);
        removeShadow ? setRemoveShadow(false) : setRemoveShadow(true);
    }

    const closeMenu = () => {
        setOpenMenu(false)
        setRemoveShadow(false)
    }

    const toggleCollapse = () => {
        collapse ? setCollapse(false) : setCollapse(true);
    }

    const toggleLightMode = () => {
        lightMode ? setLightMode(false) : setLightMode(true);
        ToggleDarkTheme()
    }

    return <>
        <header className={`header  ${removeShadow ? 'remove-shadow' : ''} ${userAuth && 'auth'}`}>
            <div className={`container py-3 navigation dark`}>
                <div className="logo" onClick={() => navigate('/')}>
                    <img src={logo1} alt="dark logo" className='dark-logo w-100 h-100' />
                    <img src={logo} alt="light logo" className='light-logo w-100 h-100' />
                </div>
                <nav className="nav-links">
                    <div className="dark-light-mode-btn" onClick={toggleLightMode}>
                        <span className={` ${darkTheme ? 'active' : ''}`}>
                            <i class="fa-regular fa-moon"></i>
                        </span>
                        <span className={`light ${darkTheme ? '' : 'active'}`}>
                            <i class="fa-regular fa-sun"></i>
                        </span>
                    </div>
                    <ul className="links hidden-sm">
                        <li>
                            <Link to={'/'}>
                                القائمة الرئيسية
                            </Link>
                        </li>
                        <li>
                            <Link to={'/plateform-instraction'}>
                                تعليمات المنصة
                            </Link>
                        </li>
                        <li>
                            <Link to={'/lesson-by-code'}>
                                الاكواد
                            </Link>
                        </li>
                        {/* <li>
                            <Link to={'/'}>
                                مواعيد السنتر
                            </Link>
                        </li> */}
                    </ul>
                </nav>
                {/* check in user authenticated or not */}
                {
                    userAuth ?
                        <div className='user-auth hidden-sm'>
                            <Link to={'/user-profile'} className='user-info'>
                                <div className='user-image'>
                                    <img src={defaultUserImage} alt='' className='w-100 h-100' />
                                </div>
                                <p className='user-name m-0 p-0'>
                                    {userAuth.userName}
                                </p>
                            </Link>
                            {/* <div className='bel'>
                                <i class="fa-regular fa-bell"></i>
                            </div> */}
                            {/* <ul className="logout-dropdown">
                                <li>
                                    <Link to={'/user-profile'}>
                                        <i className="fa-regular fa-circle-user"></i>
                                        الحساب الشخصي
                                    </Link>
                                </li>
                                <li onClick={logout}>
                                    <i className="fa-solid fa-arrow-right-from-bracket"></i>
                                    تسجيل الخروج
                                </li>
                            </ul> */}
                        </div>
                        : <Link to={'/auth'} className="user-image hidden-sm d-block">
                            <img src={defaultUserImage} alt='user image' className='w-100 h-100' />
                        </Link>
                }
                {/* <div className="mobile-menu hidden-lg" onClick={showMenu}>
                    <i class="fa-solid fa-bars"></i>
                </div> */}
            </div>
        </header>

        {/* start mobile menu */}
        <div className={`mobile-menu-links ${openMenu ? 'show' : ''}`}>
            <div className='container'>
                <div className='user'>
                    {
                        userAuth ?
                            <Link to={'/user-profile'} className='user-info'>
                                <div className='user-image'>
                                    <img src={defaultUserImage} alt='' className='w-100 h-100' />
                                </div>
                                <div className='user-name'>
                                    <p className='m-0 p-0'>{userAuth.userName}</p>
                                    <p className='m-0 p-0'>{userAuth.email}</p>
                                </div>
                            </Link>
                            :
                            <Link to={'/auth'} className='user-image' onClick={closeMenu}>
                                <img className='w-100 h-100' src={defaultUserImage} />
                            </Link>
                    }
                    <ul>
                        <li>
                            <Link to={'/'} onClick={closeMenu}>
                                {/* <img src={homeLogo} /> */}
                                <i className="fa-solid fa-house"></i>
                                الصفحة الرئيسية
                            </Link>
                        </li>
                        {
                            userAuth ?
                                <>
                                    {/* <li>
                                        <Link to={'/cart'} onClick={closeMenu}>
                                            <i className="fa-solid fa-cart-shopping"></i>
                                            السلة
                                        </Link>
                                    </li> */}
                                    <li onClick={logout}>
                                        <Link onClick={closeMenu}>
                                            <i className="fa-solid fa-arrow-right-from-bracket"></i>
                                            تسجيل الخروج
                                        </Link>
                                    </li>
                                    <li className='collapse-menu' onClick={toggleCollapse}>
                                        <span>
                                            <i className="fa-solid fa-circle-info"></i>
                                            البيانات الشخصية
                                        </span>
                                        <i class={`fa-solid ${collapse ? 'fa-chevron-up' : 'fa-chevron-down'} icon`}></i>
                                    </li>
                                    <ul className={`ps-2 menu m-0 p-0 d-block ${collapse ? 'un-collapse' : ''}`}>
                                        <li onClick={closeMenu} className={`${location.pathname === '/user-profile' ? 'active' : ''}`}>
                                            <Link to={'/user-profile'} >
                                                <i className="fa-regular fa-user"></i>
                                                ملف المستخدم
                                            </Link>
                                        </li>
                                        <li onClick={closeMenu} className={`${location.pathname === '/user-profile' ? 'active' : ''}`}>
                                            <Link to={'/student-all-courses'} >
                                                <i className="fa-solid fa-book-bookmark"></i>
                                                جميع الكورسات
                                            </Link>
                                        </li>
                                        <li onClick={closeMenu} className={`${location.pathname === '/user-profile/my-courses' ? 'active' : ''}`}>
                                            <Link to={'/user-profile/my-courses'}>
                                                <i className="fa-solid fa-table-cells-large"></i>
                                                كورساتي
                                            </Link>
                                        </li>
                                        {/* <li onClick={closeMenu}>
                                            <Link to={'/user-profile/security-and-login'}>
                                                <i className="fa-solid fa-shield"></i>
                                                الأمان و تاريخ تسجيل الدخول
                                            </Link>
                                        </li> */}
                                        <li onClick={closeMenu}>
                                            <Link to={'/user-profile/invoices'}>
                                                <i className="fa-regular fa-file-excel"></i>
                                                الفواتير
                                            </Link>
                                        </li>
                                        <li onClick={closeMenu}>
                                            <Link to={'/user-profile/course-subscreptions'}>
                                                <i className="fa-solid fa-circle-plus"></i>
                                                الاشتراكات
                                            </Link>
                                        </li>
                                        <li onClick={closeMenu} className={`${location.pathname === '/user-profile/my-exam-score' ? 'active' : ''}`}>
                                            <Link to={'/user-profile/my-exam-score'}>
                                                <i className="fa-regular fa-circle-check"></i>
                                                نتائج الامتحانات
                                            </Link>
                                        </li>
                                        <li onClick={closeMenu}>
                                            <Link to={'/user-profile/my-homework-score'}>
                                                <i className="fa-regular fa-clipboard"></i>
                                                نتائج الواجب
                                            </Link>
                                        </li>
                                        {/* <li onClick={closeMenu}>
                                            <Link to={'/user-profile'}>
                                                <i className="fa-solid fa-display"></i>
                                                نتائج كويز الفيديو
                                            </Link>
                                        </li> */}
                                        <li onClick={closeMenu}>
                                            <Link to={'/user-profile/notification'}>
                                                <i className="fa-regular fa-bell"></i>
                                                الاشعارات
                                            </Link>
                                        </li>
                                        {/* <li onClick={closeMenu}>
                                            <Link to={'/user-profile/level-indicator'}>
                                                <i className="fa-solid fa-arrow-trend-up"></i>
                                                مؤشر المستوي
                                            </Link>
                                        </li> */}
                                    </ul>
                                </>
                                :
                                <>
                                    <li>
                                        <Link to={'/auth/register'} onClick={closeMenu}>
                                            <img src={newUserLogo} />
                                            حساب جديد
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={'/auth'} onClick={closeMenu}>
                                            <img src={loginLogo} />
                                            تسجيل الدخول
                                        </Link>
                                    </li>
                                </>
                        }
                    </ul>
                </div>
                <ul className='other-links'>
                    {/* <li>
                        <Link to={'/login'} onClick={closeMenu}>
                            مواعيد السنتر
                        </Link>
                    </li> */}
                    <li>
                        <Link to={'/lesson-by-code'} onClick={closeMenu}>
                            الاكواد
                        </Link>
                    </li>
                    <li>
                        <Link to={'/plateform-instraction'} onClick={closeMenu}>
                            تعليمات المنصة
                        </Link>
                    </li>
                    {/* <li>
                        <Link to={'/'} onClick={closeMenu}>
                            القائمة الرئيسية
                        </Link>
                    </li> */}
                </ul>
                <div className='dark-light-mode py-3'>
                    <div className='right-btn'>
                        <div className='icon'>
                            <i className={`fa-regular ${!darkTheme ? 'fa-moon' : 'fa-sun'}`}></i>
                        </div>
                        {
                            !darkTheme ?
                                'الانتقال للوضع الليلي'
                                :
                                'الانتقال للوضع النهاري'
                        }
                    </div>
                    <div className="left-btn" onClick={toggleLightMode}>
                        <span className={`dark ${darkTheme ? 'active' : ''}`}>
                            <i className="fa-regular fa-moon"></i>
                        </span>
                        <span className={`light ${darkTheme ? '' : 'active'}`}>
                            <i className="fa-regular fa-sun"></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </>
}
