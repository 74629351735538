import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import config from '../../../config'
import { auth } from '../../../Context/AuthStore'
import Placholder from '../../../Components/Used/Placeholder'
import Swal from 'sweetalert2'

export default function ExamsScore() {

    const [examsScore, setExamsScore] = useState(false)
    const { userAuth } = useContext(auth)
    const [currentPage, setcurrentPage] = useState(1)
    const [haveNext, sethaveNext] = useState(false)
    const navigate = useNavigate()

    const move = (e) => {
        if (e.target.getAttribute('role') == 'next') {
            console.log(currentPage)
            if (haveNext) {
                setcurrentPage((prev) => prev + 1)
            }
        } else {
            if (currentPage > 1) {
                setcurrentPage((prev) => prev - 1)
            }
        }
    }

    const showExamAnswers = (exam_id, exam_name, result_date) => {
        let date = new Date()
        let rDate = new Date(result_date)

        if (result_date != undefined) {
            if (date.getFullYear() >= rDate.getFullYear() && date.getMonth() >= rDate.getMonth() && date.getDate() >= rDate.getDate()) {
                navigate(`/user-profile/show-exam-answers/${exam_id}/${exam_name}`)
            } else {
                Swal.fire({
                    text: `سيتم عرض اجابات الامتحان يوم ${getHumanDate(result_date)}`,
                    icon: 'info'
                })
            }
        } else {
            navigate(`/user-profile/show-exam-answers/${exam_id}/${exam_name}`)
        }
    }

    const getExamsScore = () => {
        setExamsScore(false)
        axios.get(`${config.basURL}exam/exam-results?page=${currentPage}`, {
            headers: {
                'Authorization': config.ApiKey,
                'auth': `Bearer ${userAuth}`
            }
        }).then((res) => {
            console.log(res.data)
            let next = res.data.next ? true : false;
            sethaveNext(next)
            let data = res.data.results.filter((exam) => {
                return exam.exam !== null;
            })
            setExamsScore(data)
        }).catch((err) => {
            console.log(err)
        })
    }

    function getHumanDate(d) {
        const date = new Date(d);
        const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
        return date.toLocaleDateString('ar', options);
    }

    useEffect(() => {
        getExamsScore()
    }, [userAuth, currentPage])

    return <>
        <div className='container exam-score-section' style={{minHeight:'100vh', paddingTop: '2rem'}}>
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">
                            التسلسل
                        </th>
                        <th scope="col">
                            أسم الامتحان
                        </th>
                        <th scope="col">
                            عدد الاسئلة
                        </th>
                        <th scope="col">
                            الدرجه النهائيه
                        </th>
                        <th scope="col">
                            درجه الطالب
                        </th>
                        <th scope="col">
                            عدد الاسئلة المحلولة
                        </th>
                        <th scope="col">
                            الاجابات
                        </th>
                        <th scope="col">
                            اعاده الامتحان
                        </th>
                        <th scope="col">
                            الوقت المستغرق
                        </th>
                        <th scope="col">
                            وقت بدأ
                            الامتحان
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        examsScore ?
                            examsScore.map(exam => <tr>
                                <td>{exam.id}</td>
                                <td>
                                    {exam.exam.name}
                                </td>
                                <td>
                                    {exam.exam.exam_questions_count}
                                </td>
                                <td> {exam.exam.exam_degree} </td>
                                <td>
                                    {Math.floor(exam.degree / exam.exam.exam_degree * 100)} %
                                    <p>
                                        {
                                            Math.floor(exam.degree / exam.exam.exam_degree * 100) < 50 && 'اي الدرجه الزفت دي'
                                        }
                                        {
                                            (Math.floor(exam.degree / exam.exam.exam_degree * 100) > 50 && Math.floor(exam.degree / exam.exam.exam_degree * 100) < 99) && 'شد شويه يا برنس'
                                        }
                                        {
                                            (Math.floor(exam.degree / exam.exam.exam_degree * 100) == 100) && 'لماح وحوت كيميا'
                                        }
                                    </p>
                                </td>
                                <td className='score-bar'>
                                    <span>{exam.question_answer.length}</span>
                                    <div className='bar'>
                                        <span style={{ width: `${Math.floor(exam.question_answer.length / exam.exam.exam_questions_count * 100)}%` }}></span>
                                    </div>
                                </td>
                                <td>
                                    <button onClick={() => showExamAnswers(exam.id, exam.exam.name, exam.exam.result_date)}>
                                        عرض الاجابات
                                    </button>
                                </td>
                                <td>
                                    {
                                        exam.exam.is_many_times ? <Link className='retake' to={`/quiz/${exam.exam.course}/${exam.exam.id}`}> اعاده الامتحان </Link> : <p className='bg-danger'> الامتحان لمره واحده </p>
                                    }
                                </td>
                                <td className='score-bar'>
                                    {exam.time_taken} دقيقه
                                </td>
                                <td>
                                    {getHumanDate(exam.exam.created)}
                                </td>
                            </tr>)
                            : <tr>
                                <td colSpan={10}>
                                    <Placholder />
                                </td>
                            </tr>
                    }
                </tbody>
            </table>
            <div className="next-prev">
                <button onClick={move} className={`${currentPage == 1 ? 'disabled' : ''}`} role='prev' disabled={currentPage == 1}> السابق </button>
                <button onClick={move} className={`${haveNext ? '' : 'disabled'}`} role='next' disabled={haveNext ? false : true}> التالي </button>
            </div>
        </div>
    </>
}
