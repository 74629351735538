import React, { useContext, useEffect, useState } from 'react'
import CourseUniteBox from '../../Components/UserCourses/CourseUniteBox'
import axios from 'axios'
import config from '../../config'
import { Link, useParams } from 'react-router-dom';
import { auth } from '../../Context/AuthStore'
import Placeholder from '../../Components/Used/Placeholder'
import CourseContentCollapse from '../../Components/Courses/CourseContentCollapse';

import img1 from '../../assets/herobanner__1.png'
import img2 from '../../assets/herobanner__2.png'
import img3 from '../../assets/herobanner__5.png'

export default function UserCoursePreview() {

  const [courseData, setCourseData] = useState(false);
  const [units, setUnits] = useState(false)
  const { course_id } = useParams()
  const { userAuth } = useContext(auth)
  const [courseExams, setCourseExams] = useState(false)
  const [studentViews, setstudentViews] = useState(false)

  const getCourseExams = () => {
    axios.get(`${config.basURL}exam/exam-course-list/${course_id}`, {
      headers: {
        'Authorization': config.ApiKey,
        'auth': `Bearer ${userAuth}`
      }
    }).then((res) => {
      setCourseExams(res.data);
    }).catch((err) => console.log(err))
  }

  const getCourseData = () => {
    axios.get(`${config.basURL}course/course-details/${course_id}`, {
      headers: {
        'Authorization': config.ApiKey
      }
    }).then((res) => {
      console.log(res.data)
      setCourseData(res.data)
      // setUnits(res.data.course_units)
    }).catch((err) => console.log(err))
  }

  const getUnits = () => {
    axios.get(`${config.basURL}subscription/access-course/${course_id}`, {
      headers: {
        'Authorization': config.ApiKey,
        'auth': `Bearer ${userAuth}`
      }
    }).then((res) => {
      console.log(res.data)
      setUnits(res.data.units)
      setstudentViews(res.data.views)
    }).catch((err) => console.log(err))
  }

  useEffect(() => {
    getCourseData()
    getUnits()
    getCourseExams()
  }, [])

  return <>
    <div className='user-course-preview-title'>
      <img src={img1} alt="" />
      <img src={img2} alt="" />
      <img src={img3} alt="" />
      <h1 className='fw-bold'>
        {courseData && courseData.name}
      </h1>
      <p>
        {courseData && courseData.about}
      </p>

    </div>


    <div className='d-flex justify-content-center align-items-center'>
      <a href="https://montada.mahmoud-elgharabawy.com/" target='_blank' className='go-to-montada'> الدخول اللي المنتدي </a>
    </div>
    <div className='user-course-preview-content container'>



      <h3 className='py-3 mt-4 mb-4'>
        محتوي الكورس
      </h3>
      {/* <div className='row mb-5'>
        {
          units && units.map((unit) => <CourseContentCollapse key={unit.id} data={unit} />)
        }
      </div> */}
      <div className='row gap-3 mb-5'>
        {
          units && units.map((unit) => <CourseUniteBox data={unit} views={studentViews} />)
        }
      </div>

      <div className="separate"></div>

      <h5 className='py-3 mt-4'>
        فايلات
      </h5>
      <div className='course-files mb-5 py-3'>
        {
          courseData.course_file ? courseData.course_file.length > 0 ? courseData.course_file.map(file => <a href={`${file.file}`} className='file d-block' target='_blank'>
            <div className="icon">
              <i className="fa-solid fa-file"></i>
            </div>
            <p className='m-0'>
              {
                decodeURIComponent(file.file.split('/')[file.file.split('/').length - 1])
              }
            </p>
          </a>) : <p className='w-100 m-0 py-2 text-center'> مفيش فايلات في الكورس </p> : <Placeholder />
        }
      </div>

      <div className="separate"></div>

      <div className="total-course-exams row gap-5 mb-5">
        {courseExams && courseExams.map(exam => {
          return <div className="col-lg-12" key={exam.id}>
            <h6 className="title w-100"> {exam.name} <span className='exam-duration'> <i className="fa-regular fa-clock"></i> {exam.exam_minutes}  دقائق </span> </h6>
            <p>{exam.description}</p>
            <Link to={`/quiz/${course_id}/${exam.id}`}>
              ابدأ
            </Link>
          </div>
        })}
      </div>
    </div>

  </>
}
