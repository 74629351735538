import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import config from '../../../config'
import { auth } from '../../../Context/AuthStore'
import Placeholder from '../../../Components/Used/Placeholder'
import Swal from 'sweetalert2'
import Validator from "../../../Validator"
import CSVMake from '../../../CSVMake'

export default function CreateCopon() {

    const [courseList, setcourseList] = useState(false)
    const { userAuth } = useContext(auth);
    const [isCreateing, setIsCreateing] = useState(false)
    const [selectedLesson, setselectedLesson] = useState([])
    const [createdCopons, setCreatedCopons] = useState(false)
    const [createdCourseData, setcreatedcourseData] = useState(false)
    const [studentCreatedCodes, setStudentCreatedCodes] = useState(false)
    const [placeYear, setPlaceYear] = useState(false)
    const [createData, setCreateData] = useState({
        lesson_id: '',
        quantity: '',
        view_counter: ''
    })
    const [studentCodeData, setStudentCodeData] = useState({
        place_id: '',
        type_education_id: '',
        quantity: '',
        year_id: '3'
    })
    const [courseCode, setcourseCode] = useState({
        course_id: '',
        quantity: ''
    })

    const updateCourseCodeData = (e) => {
        let data = { ...courseCode }
        data[e.target.name] = e.target.value;
        setcourseCode(data)
    }

    const updateCreateData = (e) => {
        let data = { ...createData }
        data[e.target.name] = e.target.value;
        setCreateData(data)
    }

    const updateStudentCodeData = (e) => {
        let data = { ...studentCodeData }
        data[e.target.name] = e.target.value;
        setStudentCodeData(data)
    }

    const chooseCourse = (e) => {
        let courseUnits = courseList.filter(course => course.id === Number(e.target.value))[0].course_units;
        let lessons = []
        courseUnits.map(unit => unit.unit_lessons.map(lesson => lessons.push(lesson)))
        setselectedLesson(lessons)
    }

    const getPlacesYear = () => {
        axios.get(`${config.basURL}student/student-extra`, {
            headers: {
                'Authorization': config.ApiKey,
                'auth': `Bearer ${userAuth}`
            }
        }).then(res => {
            console.log(res.data)
            setPlaceYear(res.data)
        }).catch(err => {
            console.log(err)
        })
    }

    const getCourseList = () => {
        axios.get(`${config.basURL}center/center-course-list`, {
            headers: {
                'Authorization': config.ApiKey,
                'auth': `Bearer ${userAuth}`,

            }
        }).then((res) => {
            setcourseList(res.data)
        }).catch((err) => {
            console.log(err)
        })
    }

    const createCopons = (e) => {
        e.preventDefault();
        setIsCreateing(true)
        let validate = new Validator
        validate.setKey('course_id').setValue(createData.lesson_id).required();
        validate.setKey('quantity').setValue(createData.quantity).required();
        validate.setKey('view_counter').setValue(createData.view_counter).required();

        if (validate.haveError) {
            setIsCreateing(false)
            Swal.fire({
                text: 'جميع الحقول مطلوبه',
                icon: 'error'
            })
            return;
        }

        axios.post(`${config.basURL}dashboard/codes-lesson-generate`, createData, {
            headers: {
                'Authorization': config.ApiKey,
                'auth': `Bearer ${userAuth}`
            }
        }).then(res => {
            setIsCreateing(false)
            setCreatedCopons(res.data)
            setStudentCreatedCodes(false)
        }).catch(err => {
            setIsCreateing(false)
            console.log(err)
        })
    }

    const createStudentCode = (e) => {
        e.preventDefault();
        setIsCreateing(true)
        let validate = new Validator
        // validate.setKey('place_id').setValue(studentCodeData.place_id).required();
        // validate.setKey('year_id').setValue(studentCodeData.year_id).required();
        validate.setKey('type_education_id').setValue(studentCodeData.type_education_id).required();
        validate.setKey('quantity').setValue(studentCodeData.quantity).required();
        // validate.setKey('course').setValue(studentCodeData.course).required();

        if (validate.haveError) {
            setIsCreateing(false)
            Swal.fire({
                text: 'جميع الحقول مطلوبه',
                icon: 'error'
            })
            return;
        }

        axios.post(`${config.basURL}dashboard/codes-students-generate`, studentCodeData, {
            headers: {
                'Authorization': config.ApiKey,
                'auth': `Bearer ${userAuth}`
            }
        }).then(res => {
            setIsCreateing(false)
            setStudentCreatedCodes(res.data)
            setCreatedCopons(false)
        }).catch(err => {
            setIsCreateing(false)
            console.log(err)
        })
    }

    const createCourseCodes = (e) => {
        e.preventDefault();
        setIsCreateing(true)
        axios.post(`${config.basURL}dashboard/codes-course-generate`, courseCode, {
            headers: {
                'Authorization': config.ApiKey,
                'auth': `Bearer ${userAuth}`
            }
        }).then(res => {
            setIsCreateing(false)
            setcreatedcourseData(res.data)
            console.log(res.data)
        }).catch(err => {
            setIsCreateing(false)
            console.log(err)
        })
    }

    const exportCsv = () => {
        let csv = new CSVMake;
        csv.setTableHeader({
            code: 'الكود',
            lesson: ['name', "اسم الدرس"],
            view_counter: 'عدد مرات المشاهده'
        }).setData(createdCopons, {
            lesson: 'name'
        }).exportFile('اكواد الدروس')
    }

    const exportStudentCsv = () => {
        let csv = new CSVMake;
        csv.setTableHeader({
            code: 'الكود',
            type_education: ['name', "نوع التعليم"],
            place: ['name', 'المكان'],
            year: ['name', 'السنه الدراسية']
        }).setData(studentCreatedCodes, {
            type_education: 'name',
            place: 'name',
            year: 'name'
        }).exportFile('اكواد الطلاب')
    }

    const exportCourseCsv = () => {
        let csv = new CSVMake;
        csv.setTableHeader({
            code: 'الكود',
            course__name: 'اسم الكورس'
        }).setData(createdCourseData).exportFile(` اكواد كورس ${createdCourseData[0].course__name} `)
    }

    useEffect(() => {
        getCourseList()
        getPlacesYear()
    }, [userAuth])

    return <>
        <div className="container-fluid dashboard-create-copon">
            <div className='page-header'>
                <h1>
                    الأكواد
                </h1>
                <Link to={'/dashboard/prev-copon'} className='prev-copons'>
                    الاكواد السابقة
                </Link>
            </div>
            <div className="create-new-copon">
                <h6>
                    أستخراج الأكواد للدروس
                </h6>
                <form className="new-copon" onSubmit={createCopons}>
                    <div className="right">
                        <select name="" id="" className='type' onChange={chooseCourse}>
                            <option value="">أختر الكورس</option>
                            {
                                courseList && courseList.map(course => <option key={course.id} value={course.id}> {course.name} </option>)
                            }
                        </select>
                        <select name="lesson_id" id="" className='course-name' onChange={updateCreateData}>
                            <option>أختر اسم الدرس </option>
                            {
                                selectedLesson && selectedLesson.map(lesson => <option key={lesson.id} value={lesson.id}> {lesson.name} </option>)
                            }
                        </select>
                        <input type="text" placeholder='العدد' name='quantity' onChange={updateCreateData} />
                        <input className='me-2' type="text" placeholder='عدد مرات المشاهده' name='view_counter' onChange={updateCreateData} />
                    </div>
                    <div className="left">
                        <button className='create'>
                            {
                                isCreateing ? <i className="fa-solid fa-circle-notch fa-spin"></i> : 'أستخرج'
                            }
                        </button>
                    </div>
                </form>
                <h6 className='py-2'>
                    استخراج الاكواد للطلاب
                </h6>
                <form action="" className="new-copon" onSubmit={createStudentCode}>
                    <div className="right">
                        <select name="type_education_id" id="" onChange={updateStudentCodeData}>
                            <option value=""> اختر نوع التعليم </option>
                            {
                                placeYear && placeYear.type_education.map(type => <option key={type.id} value={type.id}> {type.name} </option>)
                            }
                        </select>
                        <input type="text" placeholder='العدد' name='quantity' onChange={updateStudentCodeData} />
                    </div>
                    <div className="left">
                        <button className='create'>
                            {
                                isCreateing ? <i className="fa-solid fa-circle-notch fa-spin"></i> : 'أستخرج'
                            }
                        </button>
                    </div>
                </form>
                <h6 className='py-2'>
                    استخراج الاكواد الكورسات
                </h6>
                <form action="" className="new-copon" onSubmit={createCourseCodes}>
                    <div className="right">
                        <select name="course_id" id="" onChange={updateCourseCodeData}>
                            <option value=""> اختر الكورس </option>
                            {
                                courseList && courseList.map(course => <option key={course.id} value={course.id}> {course.name} </option>)
                            }
                        </select>
                        <input type="text" placeholder='العدد' name='quantity' onChange={updateCourseCodeData} />
                    </div>
                    <div className="left">
                        <button className='create'>
                            {
                                isCreateing ? <i className="fa-solid fa-circle-notch fa-spin"></i> : 'أستخرج'
                            }
                        </button>
                    </div>
                </form>
            </div>
            <div className="resent-copons">
                {
                    createdCopons && <div className="section-header">
                        <button className='export-file' onClick={exportCsv}>
                            <i className="fa-regular fa-file-excel"></i>
                            انشاء ملف Excel
                        </button>
                    </div>
                }
                {
                    studentCreatedCodes && <div className="section-header">
                        <button className='export-file' onClick={exportStudentCsv}>
                            <i className="fa-regular fa-file-excel"></i>
                            انشاء ملف Excel
                        </button>
                    </div>
                }
                {
                    createdCourseData && <>
                        <div className="section-header">
                            <button className='export-file' onClick={exportCourseCsv}>
                                <i className="fa-regular fa-file-excel"></i>
                                انشاء ملف Excel
                            </button>
                        </div>
                        <p className='alert alert-danger my-3'> يجب تحميل ملف الاكسل بعد انشاء الاكواد  </p>
                    </>
                }
                <div className='content'>
                    {
                        createdCopons && createdCopons.map(copon => <div className='copon-row'>
                            <p className='code'>
                                {copon.code}
                            </p>
                            <p className='course-name'>
                                {copon.lesson.name}
                            </p>
                            <p className='course-name'>
                                عدد مرات المشاهده : {copon.view_counter}
                            </p>
                        </div>)
                    }
                    {
                        studentCreatedCodes && studentCreatedCodes.map(copon => <div key={copon.id} className='copon-row student-code-row'>
                            <p className='code'>
                                {copon.user.username}
                            </p>
                            {/* <p className='course-name'>
                                السنه الدراسيه : {copon.year.name}
                            </p> */}
                            {/* <p className='course-name'>
                                المكان: {copon.place.name}
                            </p> */}
                            <p className='course-name'>
                                نوع التعليم:  {copon.type_education.name}
                            </p>
                        </div>)
                    }
                    {
                        createdCourseData && createdCourseData.map((copon, index) => <div key={index} className='copon-row student-code-row'>
                            <p className='code'>
                                {copon.code}
                            </p>
                        </div>)
                    }
                </div>
            </div>
        </div>
    </>
}
