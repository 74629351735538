import React, { useContext, useEffect, useState } from 'react'
import MyCoursesBox from '../../../Components/Profile/MyCoursesBox'
import axios from 'axios'
import { auth } from '../../../Context/AuthStore'
import config from '../../../config'
import Placeholder from '../../../Components/Used/Placeholder'
export default function CourseSubscreptions() {

  const { userAuth } = useContext(auth);
  const [coursesData, setCoursesData] = useState([]);
  const [isLoading, setisLoading] = useState(false)

  const getCoursesData = () => {
    setisLoading(true)
    axios.get(`${config.basURL}student/student-courses-inactive`, {
      headers: {
        'Authorization': config.ApiKey,
        'auth': `Bearer ${userAuth}`
      }
    }).then((res) => {
      setisLoading(false)
      console.log(res.data)
      // let notActiveCourses = res.data.filter(course => !course.active)
      setCoursesData(res.data.results)
    }).catch((err) => {
      console.log(err)
      setisLoading(false)
    })
  }

  useEffect(() => {
    getCoursesData()
  }, [])

  return <>
    <div className='container my-courses-section' style={{minHeight:'100vh' , paddingTop: '2rem'}}>
      <div className='row'>
        {
          isLoading ? <Placeholder/> :  (coursesData.length > 0 ? coursesData.map(course => <MyCoursesBox active={false} key={course.course.id} data={course} />) : <h5 className='text-center'>لا يوجد كورسات حتي الان</h5>)
        }
      </div>
    </div>
  </>
}
