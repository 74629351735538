import React, { useContext, useEffect, useState } from 'react'
import incom from '../../../assets/incom.svg';
import avatar from '../../../assets/avatar.svg';
import Invoice from '../../../Components/Dashboard/Invoices/Invoice';
import axios from 'axios';
import { auth } from "../../../Context/AuthStore"
import config from '../../../config'
import Placeholder from '../../../Components/Used/Placeholder'
import Validator from '../../../Validator';
import Swal from 'sweetalert2';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

export default function SubscriptionsInvoices() {

    const { userAuth } = useContext(auth)
    const navigate = useNavigate()
    const sparams = useParams()

    const [getDataLink, setgetDataLink] = useState('/dashboard/subscriptions')

    const [invoicesData, setinvoicesData] = useState(false)
    const [count, setcount] = useState(0)
    const [money, setmoney] = useState(0)
    const [haveNext, sethaveNext] = useState(false)
    const [havePrev, sethavePrev] = useState(false)

    const [searchByCondition, setsearchByCondition] = useState('')

    const getData = () => {
        setinvoicesData(false)
        let targetLink = `${config.basURL}dashboard/invoice-list`;
        if (Object.keys(sparams).length != 0) {
            targetLink += `?${sparams.search_by}=${sparams.search_value}${`&page=${sparams.page || 1}`}`
        }

        axios.get(targetLink, {
            headers: {
                'Authorization': config.ApiKey,
                'auth': `Bearer ${userAuth}`
            }
        }).then(res => {
            // console.log(res.data)
            setinvoicesData(res.data.results)
            setcount(res.data.count)
            setmoney(res.data.results[0].total_money)
            res.data.next ? sethaveNext(true) : sethaveNext(false) 
            res.data.previous ? sethavePrev(true) : sethavePrev(false) 
        }).catch(err => {
            console.log(err)
        })

    }

    const updateSearchByLink = (e) => {
        let value = e.target.value,
            link = getDataLink;

        if(sparams.search_by){
            link = `/dashboard/subscriptions/${value}`
        }else {
            link += `/${value}`;
        }
        setgetDataLink(link)
        setsearchByCondition(value)
    }
    const updateSearchValueLink = (e) => {
        let value = e.target.value;
        let link = getDataLink;
        link += `/${value}`;
        setgetDataLink(link)
    }

    const nextPage = () => {
        let currentPage = sparams.page ? Number(sparams.page) : 1 ,
            link = '/dashboard/subscriptions'
        if(sparams.search_by){
            link += `/${sparams.search_by}/${sparams.search_value}/${currentPage += 1}`
        }else {
            link += `/ / /${currentPage += 1}`
        }
        navigate(link)
    }
    const prevPage = () => {
        let currentPage = Number(sparams.page) ,
            link = '/dashboard/subscriptions'
        if(sparams.search_by){
            link += `/${sparams.search_by}/${sparams.search_value}/${currentPage -= 1}`
        }else {
            link += `/ / /${currentPage -= 1}`
        }
        navigate(link)
    }


    const search = (e) => {
        e.preventDefault();
        navigate(getDataLink)
    }

    useEffect(() => {
        getData()
    }, [sparams])


    // student_user_username
    // course__name
    // reference_number
    //order_status
    //student__by_code
    // ${config.basURL}dashboard/invoice-list



    return <>
        <div className="container-fluid dashboard-subscriptions-invoices">
            <h1>
                الأشتراكات و الفواتير
            </h1>
            <div className='totla-incom-subscription-count'>
                <div className='total-incom'>
                    <div className='total'>
                        <h6>
                            اجمالي الارباح
                        </h6>
                        <p className='m-0'>
                            <span>{money}</span> جنيه
                        </p>
                    </div>
                    <div className="image">
                        <img src={incom} alt="" className='w-100 h-100' />
                    </div>
                </div>
                <div className="subscription-count">
                    <div className="count">
                        <h6>
                            عدد المشتركين
                        </h6>
                        <p className='m-0'>
                            {count}
                        </p>
                    </div>
                    <div className='image'>
                        <img src={avatar} alt="" className='w-100 h-100' />
                    </div>
                </div>
            </div>
            <h2 className='page-title'>
                الفواتير
            </h2>
            <div className="filter-invoices">
                <form action="" onSubmit={search} className='d-flex align-items-center justify-content-around w-100 '>
                    <div className="group d-flex flex-column" style={{ width: '30%', marginLeft: '10px' }}>
                        <label htmlFor="search_by">
                            البحث باستخدام
                        </label>
                        <select name="search_by" id="search_by" onChange={updateSearchByLink}>
                            <option value=""> اختار طريقه البحث </option>
                            <option value="student__user__username"> رقم هاتف الطالب </option>
                            <option value="course__name"> اسم الكورس </option>
                            <option value="reference_number"> reference number </option>
                            <option value="order_status"> حاله الفاتوره </option>
                            <option value="student__by_code"> مكان تسجيل الطالب </option>
                        </select>
                    </div>
                    {
                        ['student__user__username' ,'course__name' ,'reference_number'].includes(searchByCondition) &&
                        <div className="group" style={{ width: '30%', marginLeft: '10px' }}>
                            <input type="text" name='value' onChange={updateSearchValueLink} />
                        </div>
                    }
                    {
                        searchByCondition == 'order_status' &&
                        <div className="group d-flex flex-column" style={{ width: '30%', marginLeft: '10px' }}>
                            <select name='order_status' onChange={updateSearchValueLink} >
                                <option value=""> اختر حاله الفاتوره</option>
                                <option value="PAID"> تم الدفع </option>
                                <option value="UNPAID"> لم يتم الدفع </option>
                            </select>
                        </div>
                    }
                    {
                        searchByCondition == 'student__by_code' &&
                        <div className="group d-flex flex-column" style={{ width: '30%', marginLeft: '10px' }}>
                            <select name='student__by_code' onChange={updateSearchValueLink}>
                                <option value=""> اختر مكان التسجيل للطالب</option>
                                <option value="true"> طالب سنتر  </option>
                                <option value="false"> طالب اونلاين </option>
                            </select>
                        </div>
                    }
                    <button className=''> بحث </button>
                </form>
            </div>
            <div className='invoices'>

                <div className="dashboard-invoice-container">
                    {
                        invoicesData ? (
                            invoicesData.length > 0 ? invoicesData.map(invoice => <Invoice key={invoice.id} data={invoice} />) : <p className='py-3 text-center'> لا يوجد فواتير حتي الان </p>
                        ) : <Placeholder />
                    }

                </div>
                <div className="pagination-btn">
                    <button onClick={prevPage} className={`prev ${havePrev ? '' : 'disable'}`} disabled={havePrev ? false : true}> السابق </button>
                    <button className={`next ${haveNext ? '' : 'disable'}`} disabled={haveNext ? false : true} onClick={nextPage}> التالي </button>
                </div>
            </div>
        </div>
    </>
}
